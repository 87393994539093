.student-insights {
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 2rem;
  width: 50%;
  margin: 0 auto;
}

.student-insights h1 {
  text-align: center;
  margin-bottom: 2rem;
}

.student-insights form {
  display: flex;
  flex-direction: column;
}

.student-insights form label {
  margin-bottom: 0.5rem;
}

.student-insights form input[type='text'] {
  padding: 0.5rem;
  border: 1px solid #ccc;
}

.student-insights form input[type='submit'] {
  padding: 0.5rem 1rem;
  border: 1px solid #000;
  background: #000;
  color: #fff;
}

.student-insights #completion {
  text-align: center;
  margin-top: 2rem;
}
